<template>
  <div>
    <v-card>
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1">
          {{$t('account_details')}}
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-alert :value="true" type="error" v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
          {{$t('provide_account_details')}}
          <v-form ref="step1">
            <v-text-field
              v-model="user.email"
              :label= "$t('email')"
              required
              clearable
              :rules="emailRules"
            ></v-text-field>
            
            <v-autocomplete
              v-model="user.country"
              :items="countries"
              color="white"
              item-text="title"
              item-value="title"
            :label= "$t('country')"
            :rules="[value => !!value || `${$t('country')} ${$t('required')}`]"
            ></v-autocomplete>
          </v-form>

          <v-btn color="primary" @click="navigateStep2()" :disabled="disabledStep2">{{$t('continue')}}</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 2" step="2">{{$t('verification')}}</v-stepper-step>

        <v-stepper-content step="2">
          <v-alert :value="true" type="success" v-if="successMessage.length > 0">{{ successMessage }}</v-alert>
          <v-alert :value="true" type="error" v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
          {{this.$t('provide_otp')}}
          <v-form ref="step3">
            <v-text-field
              type="number"
              v-model="user.otp"
              :label= "$t('otp')"
              clearable
              :rules="otpCodeRules"
            ></v-text-field>
            <v-btn color="primary" @click="submitToken()">{{$t('continue')}}</v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-step step="3">{{$t('confirmation')}}</v-stepper-step>
        <v-stepper-content step="3">{{$t('successful_activated')}}</v-stepper-content>
      </v-stepper>
    </v-card>

    <!-- <div class="text-right py-4">
      <p>{{ $t('trying_to') }}<span class="font-weight-bold"> {{$t('forgot_password')}}</span>? {{$t('click')}} <a @click="$router.push('/forgotpassword')">{{$t('here')}}</a>.</p>
    </div> -->
  </div>
</template>

<script>
import { DataService } from "../services/DataService";

const dataService = new DataService();

export default {
  computed: {
    mobileNumberValidationRules() {
      return this.mobileNumberRules;
    }
  },
  data() {
    return {
      countries: [],
      disabledStep2: false,
      disabledStep3: false,
      currentStep: 1,
      errorMessage: "",
      successMessage: "",
      emailRules: [
        v => !!v || `${this.$t('email')} ${this.$t('required')}`,
        v => /.+@.+\..+/.test(v) || this.$t('email_valid'),
      ],
      otpCodeRules: [
        value => !!value || `${this.$t('otp_code')} ${this.$t('required')}`,
        value => (value || '').length == 6 || this.$t('six_characters'),
      ],
      user: {},
    };
  },

  mounted() {
    dataService.getConfig("countries").then(r => {
      this.countries = r;
    })
  },

  methods: {
    async navigateStep2() {
      this.disabledStep2 = true;

      let v = await this.$refs.step1.validate();
      
      if (v) {
        dataService.activateAccountRequest(this.user).then(r => {
          this.errorMessage = ""
          this.successMessage = r.message
          this.currentStep = 2
        }).catch(e => {
          this.errorMessage = this.$t(e.response.data.message)
          this.disabledStep2 = false;
          this.$store.state.showDialogMessage = false
        });
      }
    },

    async submitToken() {
      let v = await this.$refs.step3.validate();
      this.errorMessage = "";
      if (v) {
        dataService.submitRegistrationToken({
          email: this.user.email,
          otp: this.user.otp
        }).then(() => {
          this.successMessage = ""
          this.currentStep = 3;
        }).catch(() => {
          this.errorMessage = this.$t('otp_not_match');
        });
      }
    }
  }
};
</script>
